<template>
    <!-- list pay -->
    <div id="order-standard_cart" class="ResumenPay_list">

        <!-- Encabezado azul de las listas -->
        <div  class="ResumenPay_list_header">
            <div v-if="response != 4"  class="row" style="flex-flow: row nowrap">
                <span class="col-lg-7 col-sm-7 col-xs-7"> Nota de cobro / Servicio </span>
                <span class="col-lg-5 col-sm-5 text-right"> Precio / Ciclo </span>
            </div>
            <div v-else>
                <span class="col-lg-5 col-sm-5 text-right"> Transferencia </span>
            </div>
        </div>

        <!-- lista de notas de cobros iteradas  -->
        <div class="ResumenPay_list_body">
            <div v-for="(notaServicio, index) in listpay" :key="index" class="ResumenPay_list_body_note"
                :class="{ 'ResumenPay_list_body_noteAlternative': index % 2 == 0 }">
                <div class="ResumenPay_list_body_note_title">
                    <h4 class="ResumenPay_list_body_note_titleNote"> Nota de cobro N° {{ notaServicio.id }}</h4>
                    <span class="ResumenPay_list_body_note_titleService">{{ notaServicio.nombre_servicio }}</span>
                    <span v-if="!esPagoRapido" class="ResumenPay_list_body_note_subtitle">Fecha vencimiento: {{ notaServicio.vencimiento }} </span>
                </div>

                <div class="ResumenPay_list_body_note_detail">
                    <span class="ResumenPay_list_body_note_detailPrice">${{ formatNumber(notaServicio.neto) }} </span>
                    <p class="ResumenPay_list_body_note_detailPriceDetail" v-if="notaServicio.periodicidad">
                        {{ notaServicio.moneda }} / {{ notaServicio.periodicidad }}
                    </p>
                </div>

            </div>
        </div>

        <!-- Texto informativo que aparecen dependiendo del response -->
        <div v-if="response == 1" class="ResumenPay_list_messages">
            <ul class="ResumenPay_list_messages_ul">

                <li v-if="esPagoRapido" class="ResumenPay_list_messages_ul_item">
                    <span class="pt-icon-list-icon"><i aria-hidden="true" class="ion ion-android-done-all"></i></span>
                    <span class="pt-icon-list-text">Si el servicio estaba suspendido y todas las notas vencidas están
                        pagadas, se reactivará.</span>
                </li>

                <li v-if="!esPagoRapido" class="ResumenPay_list_messages_ul_item">
                    <span class="pt-icon-list-icon"><i aria-hidden="true" class="ion ion-android-done-all"></i></span>
                    <span class="pt-icon-list-text">Recibiras un correo con los datos de acceso de tu cuenta, que disfrutes
                        tu servicio!</span>
                </li>

                <li class="ResumenPay_list_messages_ul_item">
                    <span class="pt-icon-list-icon"><i aria-hidden="true" class="ion ion-android-done-all"></i></span>
                    <span class="pt-icon-list-text">Si tienes dudas, no dudes en comunicarte con nuestro canal de soporte
                        via tickets, chat o teléfono.</span>
                </li>

            </ul>
        </div>

        <!-- Texto en caso de pago FALLIDO -->
        <div v-else-if="response == 2 || response == 0" class="pt-icon-list">
            <p class="mb-3">Las posibles causas de este rechazo son:</p>

            <ul class="ResumenPay_list_messages_ul">
                <li class="ResumenPay_list_messages_ul_item">
                    <span class="pt-icon-list-icon"><i aria-hidden="true" class="ion ion-android-done-all"></i></span>
                    <span class="pt-icon-list-text">Error en el ingreso de los datos de su tarjeta de crédito o débito
                        (fecha y/o código de seguridad).</span>
                </li>

                <li class="ResumenPay_list_messages_ul_item"><span class="pt-icon-list-icon"><i aria-hidden="true"
                            class="ion ion-android-done-all"></i></span><span class="pt-icon-list-text">
                        Su tarjeta de crédito o débito no cuenta con el cupo necesario para realizar la compra.</span>
                </li>

                <li class="ResumenPay_list_messages_ul_item">
                    <span class="pt-icon-list-icon"><i aria-hidden="true" class="ion ion-android-done-all"></i></span>
                    <span class="pt-icon-list-text">Tarjeta aún no habilitada en el sistema financiero.</span>
                </li>
            </ul>

            <hr class="mb-2" />
            <p v-if="listpay.length != 0" class="mb-1">
                Si desea volver a intentar su pago con WebPay, haga clic en el botón reintentar pago.
            </p>

            <p class="mb-3"> Si su pago sigue siendo rechazado, lo invitamos a realizar el pago través de la siguiente
                cuenta corriente:</p>
            <p class="mb-3">
                Banco de Chile<br />
                Titular Cuenta: ihosting Servicios Internet Ltda.<br />
                RUT: 76.351.470-6<br />
                Número de Cuenta: 058-01624-04<br /><br />

                Una vez cancelado, recordamos a usted notificar en su portal de clientes ingresando
                con su nombre de usuario y contraseña, dirigiéndose a “Billetera” junto con ello puede
                enviar copia del depósito o transferencia indicando el dominio o RUT asociado al
                correo electrónico: transferencias@ihosting.cl para de esta manera validar y comprobar
                su pago.<br /><br />
                <b>**IMPORTANTE** Recuerde que mientras su pago no haya sido notificado, en nuestros registros su nota de
                    cobro seguirá figurando como impaga.</b>
            </p>
        </div>

        <!-- Texto en caso de pago por TRANSFERENCIA -->
        <div v-else-if="response == 4" class="pt-icon-list">
            <p class="mb-3">
                Una vez cancelado, recordamos a usted notificar en su portal de clientes ingresando
                con su nombre de usuario y contraseña, dirigiéndose a “Billetera” junto con ello puede
                enviar copia del depósito o transferencia indicando el dominio o RUT asociado al
                correo electrónico: transferencias@ihosting.cl para de esta manera validar y comprobar
                su pago.<br /><br />
                <b>**IMPORTANTE** Recuerde que mientras su pago no haya sido notificado, en nuestros registros su nota de
                    cobro seguirá figurando como impaga.</b>
            </p>
        </div>

        <div v-else class="view-cart-items">
            <hr />
            <h5 style="font-size: 14px; letter-spacing: 1px; text-transform: uppercase">
                Configure al menos un servicio para continuar
            </h5>
        </div>

    </div>
</template>



<script>
import { formatNumber} from "@/helpers";

export default {

    props: {
        listpay: {
            default: () => [],
            type: Array
        },
        esPagoRapido: {
            default: false,
            type: Boolean
        },
        response: {
            default: 0,
        },
        notasCobroNeto: {
            default: 0,
            type: Number
        }
    },

    methods: {
        formatNumber
    },

}

</script>

<style>
/* RESUMEN LIST */

.ResumenPay_list {
    width: 60%;
    overflow: hidden;
    border-radius: 10px;
}



.ResumenPay_list_header {
    margin: 0;
    background-color: var(--primary-color);
    color: #fff;
    font-size: 0.9em;
    border-radius: 4px 4px 0 0;
    padding: 5px 20px;
}


/* RESUMEN LIST  (BODY) */


.ResumenPay_list_body {
    border-bottom: 2px solid var(--primary-color);
    margin-bottom: 10px;
}

.ResumenPay_list_body_note {
    display: flex;
    padding: 10px;
}

.ResumenPay_list_body_noteAlternative {
    background: rgba(29, 135, 255, 0.042);
}

.ResumenPay_list_body_note_title {
    width: 75%;
    display: flex;
    flex-direction: column;
}

.ResumenPay_list_body_note_titleNote {
    font-size: 20px;
}

.ResumenPay_list_body_note_titleService {
    font-size: 15px;
}
.ResumenPay_list_body_note_subtitle{
    font-size: 10px;
    margin: 5px 0;
}

.ResumenPay_list_body_note_detail {
    width: 25%;
    font-size: 15px;
    font-weight: 500;
    text-align: end;
}


.ResumenPay_list_body_note_detailPriceDetail{
    margin-bottom: 10px !important;
}
/* RESUMEN LIST  (MESSAGES PAY FINISH) */
.ResumenPay_list_messages {
    padding: 10px;
    margin: 25px 0px;
    font-size: 15px;
    width: 90%;
}

.ResumenPay_list_messages_ul {
    padding: 0 !important;
    list-style: none;
}

.ResumenPay_list_messages_ul_item {
    font-family: var(--body-fonts);
    font-weight: 400;
    color: var(--dark-color);
    align-self: center;
    margin-bottom: 10px;
}</style>