<template>
  <section
    class="config cd-main-content pb-80 blog sec-bg2 motpath boxshadowcustom section_"
    :class="{ 'event-none-v': processCart }"
  >
    <!-- CARGA PAGO OVERLAY  -->
    <transition name="fade">
      <div v-show="processCart" class="overlay-pago" :class="{ 'overlay-pago-display': processCart }">
        <div id="pt-loading" style="background: transparent">
          <div id="pt-loading-center">
            <img src="images/logo_negro.png" class="logo-loading" alt="loading" /><br />
            <img src="images/1480.gif" class="spinner-loading" alt="loading" />
          </div>
        </div>
      </div>
    </transition>

    <!-- HEADER CARRITO -->
    <div class="pt-breadcrumb pt-breadcrumb-cart custom-background">
      <div class="bg-logo-banner" style="z-index: 0">
        <img src="images/isotipo.png" alt="ihosting" style="max-width: 100%" />
      </div>
      <div class="container">
        <div class="row align-items-center">
          <div class="col-lg-8"></div>
          <div class="col-lg-4 ilustra-home">
            <div class="pt-breadcrumb-img text-right wow fadeInRight">
              <img src="images/about-us/Group-510-1.svg" class="img-fluid" alt="seozie-img" />
            </div>
          </div>
        </div>
      </div>
    </div>
    <!-- STEPS CARRITO -->
    <div class="container">
      <transition name="slide-fade" tag="div">
        <div class="row">
          <div class="col-md-12 col-lg-8 pt-80" style="margin-top: -140px !important">
            <div class="wrap-blog">
              <div class="row">
                <div class="col-md-12 col-lg-12">
                  <div class="container-steps-v" style="padding: 0">
                    <!-- STEP 1 / CATEGORÍAS -->
                    <transition name="slide-fade">
                      <div v-show="currentStep == 1">
                        <step-one :finish="finish" @next="next_one" />
                      </div>
                    </transition>

                    <!-- STEP 2 / SUB CATEGORÍAS Y SERVICIOS -->
                    <transition name="slide-fade">
                      <div v-show="currentStep == 2">
                        <step-two
                          @back="back_two"
                          @next="next_two"
                          :category="category"
                          :subCategories="getSubCategories_"
                          :step="currentStep"
                        />
                      </div>
                    </transition>

                    <!-- STEP 3 / CONFIGURACIÓN DE SERVICIO -->
                    <transition name="slide-fade">
                      <div v-show="currentStep == 3">
                        <step-three
                          :item="service"
                          :subcategory="subCategory"
                          :step="currentStep"
                          :validate_="validate_"
                          :contrato="category.contrato"
                          @back="back_"
                          @goToCart="goToCart"
                          @goToCheckout="goToCheckout"
                        />
                      </div>
                    </transition>

                    <!-- STEP 4 / CHECKOUT -->
                    <transition name="slide-fade" tag="div" class="row">
                      <div v-show="currentStep == 4" style="width: 100%">
                        <checkout
                          :cart="getCart"
                          :refresh_load="refresh_load"
                          @edit="editItem"
                          @back="back2"
                          @goToCategory="goToCategory"
                        />
                      </div>
                    </transition>
                  </div>
                </div>
              </div>
            </div>
          </div>

          <!-- SIDEBAR -->
          <div class="col-md-12 col-lg-4 px-0 px-lg-3">
            <aside id="sidebar" class="sidebar sec-bg1 sidebar-position pt-fancybox2-active card-new">
              <!-- CARRITO / DETALLE -->
              <cart-component
                v-if="currentStep != 3 && currentStep != 4"
                :cart="getCart"
                :redirect="redirect"
                :type="1"
                @next="next_checkout"
                @edit="editItem"
                @deleteItem="deleteItem_"
              />

              <!-- DETALLE SERVICIO / PLAN SELECCIONADO -->
              <detail-component
                v-if="currentStep == 3"
                :attributes="service.atributos"
                :detail="getDetail"
                :detailServiceConfig="detailServiceConfig"
                :showNavbar="showNavbar"
                @deleteItem="deleteItem_"
                :back="back_"
              />

              <!-- METODO DE PAGO / CHECKOUT -->
              <payment-component
                v-if="currentStep == 4"
                :cart="getCart"
                :user="getUser"
                @goToCategory="goToCategory"
                @processCartStart="processCart = true"
                @processCartFinish="processCart = false"
                @refreshAllView="refreshView"
                @get_response_cupon_selected="get_response_cupon"
              />
            </aside>
          </div>
          <!-- </transition> -->

          <!-- COMPONENTE FOOTER -->
          <footer-component
            @back="back"
            @goToCheckout="goToCheckout"
            @goToCart="goToCart"
            :service="service"
            :step="currentStep"
            :contrato="category.contrato"
            class="only-responsive"
            id="vue-footer"
          />
        </div>
      </transition>

      <modal
        v-if="service"
        :name="'contrato' + service.id"
        transition="fade"
        :min-width="200"
        :min-height="600"
        :scrollable="true"
        height="auto"
        :width="setProps"
        classes="border-none"
        @closed="hide()"
        style="z-index: 1001 !important;"
      >
        <div class="modal-content modal-content_contrato">
          <div
            class="modal-header"
            style="
              z-index: 1001;
              border: none;
              width: 100%;
              background: #353535;
              padding: 0.3rem 1rem;
            "
          >
            <button @click="hide" 
            alt="ocultar carrito" aria-label="ocultar carrito" 
            type="button" class="bootbox-close-button close" style="color: white">
              ×
            </button>
          </div>
          <div class="modal-content_contrato_contentPdf">
            <div v-if="resolucion">
              <transition name="fade">
                <div id="pdf-view" style="height: 410px"></div>
              </transition>
            </div>
            <div v-else style="height:98%">
              <embed
                v-if="category"
                :src="baseUrl + category.contrato.path_archivo"
                type="application/pdf"
                width="100%"
                height="100%"
              />
            </div>
          </div>
          <div class="modal-content_contrato_contentBtn">
            <button
              @click="addService_(goto)"
              alt="aceptar contrato ihosting" aria-label="aceptar contrato ihosting"
              type="button"
              class="modal-content_contrato_contentBtn_btn"
            >
              Aceptar contrato
            </button>
          </div>
        </div>
      </modal>


    </div>

    <!-- modal start -->
    <div class="popup-container" :class="{'active': modal_cupon_state}">
      <div v-if="cuponResponse" class="popup-box">
            <h1 v-if="cuponResponse.title">{{cuponResponse.title}}</h1>
            <p v-if="cuponResponse.comment">
              {{ cuponResponse.comment }}
            </p>
            <button class="close-btn" @click="modal_cupon_state = !modal_cupon_state">OK</button>
      </div>
    </div>
    <!-- modal end -->
  </section>
</template>

<script>
import {
  formatNumber,
  toArray,
  setPriceService,
  setTotalPriceService,
  setMasterTotalPriceService,
  returnQuantityService,
  resolucion,
} from "@/helpers";
import { mapActions, mapGetters, mapMutations } from "vuex";
import stepOne from "@/components/cart/steps/step-one"; // CATEGORIAS
import stepTwo from "@/components/cart/steps/step-two"; // SUB CATEGORIAS Y SERVICIOS
import stepThree from "@/components/cart/steps/step-three"; // SERVICIO
import checkout from "@/components/cart/steps/checkout"; // CHECKOUT

import cartComponent from "@/components/cart/CartComponent";
import detailComponent from "@/components/cart/DetailComponent";
import paymentComponent from "@/components/cart/PaymentComponent";
import footerComponent from "@/components/cart/FooterComponent";

export default {
  name: "Cart",
  components: {
    stepOne,
    stepTwo,
    stepThree,
    checkout,

    cartComponent,
    detailComponent,
    paymentComponent,
    footerComponent,
  },
  data: () => ({
    currentStep: 1,
    finish: false,
    category: {
      contrato: null,
    },
    subCategory: null,
    service: null,
    showDeleteDialog: false,

    detailServiceConfig: [],

    showNavbar: true,
    lastScrollPosition: 0,

    validate_: false,

    goto: null,
    redirect: true,
    isEditing: false,
    step: {
      oldstep: 1,
      newstep: 1,
    },
    mount: false,
    processCart: false,
    loading_modal: false,
    pdfAPIReady: false,
    adobeDCView: null,
    pdfSelected: false,

    refresh_load: false,

    modal_cupon_state: false,

    cuponResponse: null,
    // estado que indica que el servicio debe cargar con cupon:
    cuponActive: true,
  }),

  watch: {
    currentStep(step, oldStep) {
      if (step > oldStep && !this.$route.query.service && !this.$route.query.step) {
        history.pushState({ change: true }, null, document.URL);
      }

      this.step.oldstep = oldStep;
      this.step.newstep = step;
      if ((step == 2 && oldStep == 1) || (step == 2 && oldStep == 3)) {
        this.getSubCategories(this.category.id);
      }
    },
    getDetail(service) {
      this.detailServiceConfig = service.servicios_adicionales;

    },
    pdfAPIReady(val) {
      if (val) {
        this.adobeDCView = new AdobeDC.View({
          clientId: process.env.VUE_APP_API_ADOBE_TOKEN,
          divId: "pdf-view",
        });
        this.adobeDCView.previewFile(
          {
            content: {
              location: { url: process.env.VUE_APP_PORTAL_URL + this.category.contrato.path_archivo },
            },
            metaData: { fileName: "Contrato" },
          },
          { embedMode: "SIZED_CONTAINER" }
        );
      }
    },
    cuponResponse(val){
      if(val.type_error == "new_client"){
        this.modal_cupon_state = true;
        this.cuponResponse.title = "Cupón exclusivo para clientes nuevos";
        this.cuponResponse.comment = "Lamentablemente, no es posible aplicar este cupón a tu cuenta actual. Si deseas adquirir el servicio con una promoción, por favor, contáctanos para más información."
        this.deleteCuponFantasmaService();
        this.refreshView();
      }
    }
  },
  created() {
    this.getCategories().then((res) => {
      setTimeout(() => {
        this.scrollToTop();
      }, 200);
      this.goToService();
    });

    window.addEventListener(
      "popstate",
      function (e) {
        if (this.step.newstep == 2 || (this.step.newstep == 3 && !this.$route.query.service)) {
          this.currentStep--;
          e.preventDefault();
          e.returnValue = "";
        } else if (this.step.newstep == 4 && !this.$route.query.step) {
          this.currentStep = 1;
          e.preventDefault();
          e.returnValue = "";
        } else {
          e.returnValue = true;
          return true;
        }
      }.bind(this)
    );

    // si no existe un query param llamado cupon, se desactiva cuponActive el cual ayuda a que se borre el dato cuponService del servicio selecionado
    if(!this.$route.query.cupon){
        this.cuponActive = false;
    }
  },

  computed: {
    ...mapGetters([
      "getCategories_",
      "getSubCategories_",
      "getServices_",
      "getCart",
      "getDetail",
      "getConfigService",
      "getUser",
      "getData",
    ]),
    resolucion,
    data_() {
      return this.getData?.carrito;
    },

    getAdicionales() {
      return this.getDetail.servicios_adicionales;
    },
    getConfigs() {
      return this.getDetail.configuracion;
    },
    baseUrl() {
      return process.env.VUE_APP_PORTAL_URL;
    },
    setProps() {
      if (window.innerWidth < 900) {
        return "95%";
      } else {
        return "80%";
      }
    },
  },
  mounted() {
    window.addEventListener("scroll", this.onScroll);
    this.$root.$on("deleteService", (index) => this.deleteItem_(index));
  },

  beforeDestroy() {
    window.removeEventListener("scroll", this.onScroll);
  },

  methods: {
    ...mapActions(["getCategories", "getSubCategories", "getServices"]),
    ...mapMutations([
      "clearCategory",
      "addServiceToCart",
      "updateService",
      "deleteService",
      "addServiceToDetail",
      "clearDetail",
      "deleteDetail",
      "clearCart",
      "deleteCuponFantasmaService"
    ]),

    formatNumber,
    toArray,
    setPriceService,
    setTotalPriceService,
    setMasterTotalPriceService,
    returnQuantityService,
    // deleteDetailCart(detail){
    //   console.log("se ejecuta deleteDetail, detailServiceConfig Before: ", this.detailServiceConfig);
    //   this.detailServiceConfig = this.detailServiceConfig.filter( e => e.id != detail.id );
    //   console.log("se ejecuta deleteDetail, detailServiceConfig after: ", this.detailServiceConfig);

    // },

    get_response_cupon(res){
      console.log("get res: ", res);
      this.cuponResponse = res;
    },
    refreshView(){
      // console.log("refresh all view");
      this.refresh_load = true;
      // console.log("refresh_load: ", this.refresh_load);
      this.$forceUpdate();

      setTimeout(() => {
        this.refresh_load = false;
      }, 300);
    },
    // NEXT
    next() {
      this.currentStep = this.currentStep + 2;
      this.scrollToTop();
    },
    next_one(category) {
      this.category = category;
      this.currentStep = 2;
      this.scrollToTop();
    },
    next_two(params) {
      this.clearDetail();

      this.service = params.service;
      this.service["estado"] = 0;
      this.service["configuracion"] = [];
      this.service["servicios_adicionales"] = [];
      this.service["subcategory_"] = params.category;
      this.service["category_"] = this.category;

      let serviceData = JSON.parse(JSON.stringify(this.service) )
      if(!this.cuponActive){
        delete serviceData["cupon_service"];
      }
      this.addServiceToDetail(serviceData);
      // console.log("next_two: ", this.service);
      this.subCategory = params.category;
      this.currentStep = 3;
      this.scrollToTop();
    },
    // getCuponGhost()
    // {
    //   this.service["cupon_ghost"] = this.service[cupon_service];
    // },
    next_checkout() {
      this.currentStep = 4;
      this.scrollToTop();
    },

    // BACK
    back() {
      this.currentStep--;
      this.scrollToTop();
    },
    back_() {
      this.currentStep = 2;
      this.scrollToTop();
    },
    back_two() {
      this.currentStep--;
      this.scrollToTop();
      this.clearCategory();
    },
    back2() {
      this.currentStep = this.currentStep - 2;
      if (this.$route.query.step) {
        this.mount = false;
        this.currentStep = 1;
      } else {
        this.currentStep = 2;
      }

      this.scrollToTop();
    },

    // CART
    addService_(step) {
      this.$Progress.start();
      this.addService(step);
    },
    addService(step) {
      this.hide();
      if (this.service.pos != null) {
        this.updateService({ index: this.service["pos"], service: this.getDetail });
        this.$toast.success("Servicio guardado con exito.");
      } else {
        this.addServiceToCart(this.getDetail);
        this.$toast.success("Servicio agregado con exito.");
      }
      this.service.pos = null;
      this.currentStep = step;
      this.$Progress.finish();
      if (step == 1) {
        this.clearCategory();
      }
      this.scrollToTop();
    },
    editItem(index, url) {
      let service = this.getCart[index];
      if (url) {
        service["url"] = true;
      }
      service["pos"] = parseInt(index);
      let serviceData = JSON.parse(JSON.parse(JSON.stringify(service)));
      if(!this.cuponActive){
        delete serviceData["cupon_service"];
      }
      this.addServiceToDetail(serviceData);
      this.service = serviceData;
      this.subCategory = service.subcategory_;
      this.category = service.category_;

      this.currentStep = 3;

      this.currentStep = 3;
      this.scrollToTop();
    },
    scrollToTop() {
      window.scrollTo(0, 0);
    },

    deleteItem_(index) {
      this.deleteItem = index;
    },

    onScroll() {
      const currentScrollPosition = window.pageYOffset || document.documentElement.scrollTop;
      if (currentScrollPosition < 0) {
        return;
      }
      this.showNavbar = window.pageYOffset < document.body.offsetHeight - 1520;
      this.lastScrollPosition = currentScrollPosition;
    },

    show(op) {
      this.loading_modal = true;
      this.$modal.show("contrato" + this.service.id);
      this.$emit("show");

      setTimeout(() => {
        this.loading_modal = false;
        if (this.resolucion) {
          this.pdfAPIReady = true;
        }
      }, 100);
    },
    hide() {
      this.$modal.hide("contrato" + this.service.id);
      this.$emit("hide");
    },

    goToCategory() {
      this.currentStep = 2;
      this.getCategories().then((res) => {
        this.scrollToTop();
      });
    },

    goToCart() {
      if (this.getDetail.configuracion.length == 0) {
        this.$Progress.fail();
        this.validate_ = !this.validate_;
        this.scrollToTop();
      } else {
        this.show();
        this.goto = 1;
      }
    },

    goToCheckout() {
      if (this.getDetail.configuracion.length == 0) {
        this.$Progress.fail();
        this.validate_ = !this.validate_;
        $([document.documentElement, document.body]).animate(
          {
            scrollTop: $("#vue-content").offset().top,
          },
          200
        );
      } else {
        this.show();
        this.goto = 4;
      }
    },

    // IR A STEP 3 SERVICIO DESDE URL
    goToService() {
      if (this.$route.query.service && this.$route.query.category && this.$route.query.subcategory) {
        this.getCategories_.forEach((value) => {
          if (value.id == this.$route.query.category) {
            this.category = value;
          }
        });

        if (this.category) {
          this.getSubCategories(this.category.id).then((res) => {
            this.getSubCategories_.forEach((value) => {
              if (value.categoria.id == this.$route.query.subcategory) {
                this.subCategory = value.categoria;
              }
            });

            if (this.subCategory) {
              this.getServices(this.subCategory.id).then((res) => {
                this.getServices_.forEach((value) => {
                  if (value.id == this.$route.query.service) {
                    this.service = value;
                  }
                });

                if (this.service) {
                  let service = null;
                  if (this.$route.query.index) {
                    let ser = this.getCart[this.$route.query.index];
                    if (ser) {
                      this.editItem(this.$route.query.index, true);
                    }
                  } else {
                    if (this.subCategory.campos_completables.length == 0) {
                      this.$Progress.start();
                      let item = this.service;
                      item["producto"] = true;
                      item["estado"] = 0;
                      let cant = 1;
                      if (this.$route.query?.cantidad != 0) {
                        cant = this.$route.query.cantidad;
                      }
                      item["configuracion"] = {
                        cantidad: cant,
                      };
                      item["servicios_adicionales"] = [];
                      item["subcategory_"] = this.subcategory;
                      item["category_"] = this.category;
                      this.addServiceToCart(JSON.parse(JSON.stringify(item)));
                      this.$toast.success("Servicio agregado.");
                      this.currentStep = 4;
                      this.scrollToTop();

                      this.$Progress.finish();
                    } else {
                      this.next_two({
                        service: this.service,
                        category: this.subCategory,
                      });
                    }
                  }
                }
              });
            }
          });
        }
      } else if (this.$route.query.step) {
        this.currentStep = 4;
      } else if (this.$route.query.id) {
        if (this.getCart[this.$route.query.id]) {
          this.editItem(this.$route.query.id);
        }
      }

      setTimeout(() => {
        this.finish = true;
        this.redirect = false;
      }, 2000);
    },
  },
};
</script>

<style>
.container-steps-v {
  min-height: 120vh !important;
}

@media (max-width: 991px) {
  .container-steps-v {
    min-height: auto !important;
  }
}

/* MODAL CONTRATO */
.modal-content_contrato{
    position: relative;
    display: grid;
    grid-template-columns: 100%;
    grid-template-rows: 10% 79% 11%;
    width: 100%;
    height: 100%;
    pointer-events: auto;
    background-clip: padding-box;
    border: 1px solid rgba(0, 0, 0, 0.2);
    border-radius: 0.3rem;
    outline: 0;
    background: rgb(53, 53, 53) !important;
    
}
.modal-content_contrato_contentPdf{
    background: #353535; 
    height: 554px;
  }

.modal-content_contrato_contentBtn{
    height: 100%;
    background: rgb(53, 53, 53);
    z-index: 1999;
    padding: 10px;
    display: flex;
    justify-content: flex-end;
    align-items: center;

}


/* modal cupon start  */
.show-popup {
  padding: 18px 40px;
  background: #6e78ff;
  border: none;
  outline: none;
  border-radius: 6px;
  box-shadow: 0 0 10px rgba(0, 0, 0, .1);
  cursor: pointer;
  font-size: 18px;
  color: #f2f2f2;
  font-weight: 500;
}

.popup-container {
  position: fixed;
  top: 0px;
  bottom: 0px;
  width: 100%;
  height: 100%;
  background: rgba(0, 0, 0, .3);
  display: flex;
  justify-content: center;
  align-items: center;
  opacity: 0;
  pointer-events: none;
}

.popup-container.active {
  opacity: 1;
  pointer-events: auto;
  transition: .4s ease;
  z-index: 1000;
}

.popup-container .popup-box {
  width: 500px;
  background: #f2f2f2;
  border-radius: 6px;
  box-shadow: 0 0 10px rgba(0, 0, 0, .1);
  padding: 30px;
  transform: scale(0);
}

.popup-container.active .popup-box {
  transform: scale(1);
  transition: .4s ease;
  transition-delay: .25s;
}

.popup-box h1 {
  color: #333;
  text-align: center;
  font-size: 35px;
}

.popup-box p {
  color: #333;
  margin: 12px 0 20px;
}

.popup-box .close-btn {
  width: 100%;
  height: 45px;
  background: var(--primary-color);
  border-radius: 6px;
  border: none;
  outline: none;
  box-shadow: 0 0 10px rgba(0, 0, 0, .1);
  cursor: pointer;
  font-size: 18px;
  color: #f2f2f2;
  font-weight: 500;
}

/* modal cupon end  */


.modal-content_contrato_contentBtn_btn{
  margin: 0 !important;
}
/* RESPONSIVE------------------ */
@media(max-width: 650px){
  .modal-content_contrato_contentBtn{
    display: flex;
    justify-content: center;
    padding: 0 5px 10px;
    margin: 0 !important;
  }
}
@media(max-width: 500px){
  .modal-content_contrato{
    grid-template-rows: 6% 84% 10%;
  }
}

@media(max-height: 655px){
  .modal-content_contrato_contentPdf{
    height: 480px;
  }
}
@media(max-height: 605px){
  .modal-content_contrato_contentPdf{
    height: 420px;
  }
}
</style>
