<template>
  <div style="overflow: hidden">
    <div class="footer-isotipo-v mt-0" style="position: relative; height: 0px !important; width: 100%">
      <img src="images/isotipo.png" alt="ihosting-logo" class="isotipo-logo-ih pt-4" />
    </div>

    <!--
    *******************
    FOOTER
    *******************
    -->
    <footer id="pt-footer">
      <div
        class="pt-background-overley pt-opacity-1 background-footer-c"
        style="background-image: url(images/polygon/Polygon-Luminary.svg); transform: scaleX(-1)"
      ></div>
      <div class="pt-footer-style-1">
        <div class="pt-footer-top">
          <div class="container">
            <div class="row">
              <div class="col-lg-3 col-md-6">
                <div class="widget">
                  <div class="row">
                    <div class="col-sm-12">
                      <img
                        src="images/logo_blanco.svg"
                        class="pt-footer-logo img-footer"
                        alt="gym-footer-logo"
                      />
                      <p style="margin-bottom: 15px;">
                        ¡Nuestro equipo estará disponible para contestar tus dudas!
                      </p>
                      <p style="margin-bottom: 15px;">Horarios de Atención del Soporte Telefónico y Soporte Online: Lunes a viernes de 9:00 a 18:30 hrs.</p>
                      <p style="margin-bottom: 15px;">Horario de Atención Ticket: 24x7x365.</p>

                      
                      <div class="pt-contact">
                        <span>
                          <b> Soporte Telefónico: </b>
                          <p style="margin-bottom: 0px; font-weight: 500;">
                            <a style="color: white;" href="tel:+56800613900">800 613 900</a>
                          </p>
                          <p style="margin-bottom: 0px; font-size: 14px;">
                            Viña del Mar <a style="color: white;"  href="tel:+56322768037">+56-32 276 80 37</a>
                          </p>
                          <p style="margin-bottom: 0px; font-size: 14px;">
                            Santiago <a style="color: white;"  href="tel:+56225854267">+56-2 258 542 67</a>
                          </p>
                        </span>
                    </div>
                    </div>
                  </div>
                </div>
              </div>
              <div class="col-lg-2 col-md-6">
                <div class="widget">
                  <h5 class="footer-title">Hosting</h5>
                  <div class="pt-footer-container">
                    <ul class="menu" style="line-height: 1.5">
                      <li class="menu-item">
                        <router-link to="/Hostings#emprendedor">Hosting Emprendedor</router-link>
                        <!-- <a href="/hosting?id=nav-home-5#seleccionar-hosting">Hosting Emprendedor</a> -->
                      </li>
                      <li class="menu-item">
                        <router-link to="/Hostings#basico">Hosting Empresa Básico</router-link>
                        <!-- <a href="/hosting?id=nav-home-0#seleccionar-hosting">Hosting Empresa Básico</a> -->
                      </li>
                      <li class="menu-item">
                        <router-link to="/Hostings#premium">Hosting Empresa Premium</router-link>
                        <!-- <a href="/hosting?id=nav-home-1#seleccionar-hosting">Hosting Empresa Premium</a> -->
                      </li>
                      <li class="menu-item">
                        <router-link to="/Hostings#wordpress">Hosting Wordpress</router-link>
                        <!-- <a href="/hosting?id=nav-home-3#seleccionar-hosting">Hosting Wordpress</a> -->
                      </li>
                      <li class="menu-item">
                        <router-link to="/Hostings#e-learning">Hosting E-learning</router-link>
                        <!-- <a href="/hosting?id=nav-home-2#seleccionar-hosting">Hosting E-learning</a> -->
                      </li>
                      <!-- <li class="menu-item">
                        <a href="/hosting#hosting-ecoomerce">Hosting E-Commerce</a>
                      </li> -->
                    </ul>
                  </div>
                </div>

                <div class="widget">
                  <h5 class="footer-title">Conectividad</h5>
                  <div>
                    <ul class="menu" style="line-height: 1.5">
                      <li class="menu-item">
                        <a href="/conectividad#vpn">VPN Empresarial</a>
                      </li>
                      <li class="menu-item">
                        <a href="/conectividad#punto-punto">Punto-Punto</a>
                      </li>

                      <li class="menu-item">
                        <a href="/conectividad#byoip">BYOIP</a>
                      </li>
                      <li class="menu-item">
                        <a href="/conectividad#peering">Peering</a>
                      </li>
                    </ul>
                  </div>
                </div>
              </div>

              <div class="col-lg-2 col-md-6">
                <div class="widget">
                  <h5 class="footer-title">Servicios</h5>
                  <div>
                    <ul class="menu" style="line-height: 1.5">
                      <li class="menu-item">
                        <a href="/vpsSSD">VPS SSD</a>
                      </li>
                      <li class="menu-item">
                        <a href="/vpsSSDCloud">Cloud VPS</a>
                      </li>

                      <li class="menu-item">
                        <a href="/cLocation#cLocation">Co-Location</a>
                      </li>
                      <li class="menu-item">
                        <a href="/AzureIhosting">Microsoft Azure</a>
                      </li>
                      <li class="menu-item">
                        <a href="/microsoft">Microsoft 365</a>
                      </li>
                    </ul>
                  </div>
                </div>
              </div>
              <div class="col-lg-2 col-md-6">
                <div class="widget">
                  <h5 class="footer-title">Ingeniería</h5>
                  <div>
                    <ul class="menu" style="line-height: 1.5">
                      <li class="menu-item">
                        <a href="/ingenieria#administracion-infraestructura"
                          >Administración de infraestructura</a
                        >
                      </li>
                      <li class="menu-item">
                        <a href="/ingenieria#monitoreo-plataformas">Monitoreo de plataformas</a>
                      </li>

                      <li class="menu-item">
                        <a href="/ingenieria#diseño-arquitectura">Diseño arquitectura TI</a>
                      </li>
                      <li class="menu-item">
                        <a href="/ingenieria#test-stress">Test de stress</a>
                      </li>
                    </ul>
                  </div>
                </div>
              </div>
              <div class="col-lg-3 col-md-6">
                <div class="widget">
                  <h5 class="footer-title">Conversemos</h5>
                  <div class="row">
                    <div class="col-sm-12">
                      <ul class="pt-contact">
                        <li>
                          <i class="fa fa-map-marker" style="top: -5px; position: relative"></i>
                          <span>
                            <b>Dirección: </b><br />
                            <p style="margin-bottom: 5px">
                              4 norte 1233 oficina C, Viña del Mar,<br />V Región, Chile.
                            </p>
                          </span>
                        </li>
                        <li>
                          <i class="fa fa-globe" aria-hidden="true" style="top: -5px; position: relative"></i>
                          <span>
                            <b>Web: </b>
                            <p style="margin-bottom: 0px">
                              <a href="/nosotros#contacto">Contáctanos</a>
                            </p>
                            <p style="margin-bottom: 0px">
                              <a :href="enlace">Portal Clientes</a>
                            </p>
                          </span>
                        </li>
                      </ul>
                    </div>
                  </div>
                  <a href="https://partnernoc.cpanel.net/res/7151/ihostingcl.htm/">
                    <img src="images/cpanel.svg" class="pt-footer-logo img-footer" alt="gym-footer-logo" />
                  </a>
                  <span>
                    <img src="images/sinInteres.png" class="pt-footer-log" alt="cuotas_ihosting" style="width: 105px;height: 95px;margin: 0 15px;">
                  </span>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div class="pt-copyright-footer">
          <div class="container">
            <div class="row">
              <div class="col-md-5 align-self-center">
                <span class="pt-copyright">
                  <a target="_blank" href="#">
                    ihosting<sup>®</sup> | <b>Todos los derechos reservados. 2002 - {{ new Date().getFullYear() }}</b></a
                  >
                </span>
              </div>
              <div class="col-md-7 ROW">
                <ul class="payment-list">
                  <li>
                    <img width="50px" height="31px" src="img/logos/webpayplus.svg" alt="Webpay Plus" class="logo-payment min-size" />
                  </li>
                  <li>
                    <img width="50px" height="31px" src="img/payments/visa.svg" alt="Visa" class="logo-payment min-size" />
                  </li>
                  <li>
                    <img width="50px" height="39px" src="img/payments/mastercard-2.svg" alt="MasterCard" class="logo-payment min-size" />
                  </li>
                  <li>
                    <img
                      width="50px" height="50px"
                      src="img/payments/american-express-1.svg"
                      alt="American Express"
                      class="logo-payment min-size"
                    />
                  </li>

                  <li>
                    <img
                      width="50px" height="50px"
                      src="img/payments/diners-club-international-3.svg"
                      alt="Diners Club"
                      class="logo-payment min-size"
                    />
                  </li>

                  <!-- <li>
                    <img src="img/payments/khipu.svg" alt="Khipu" class="logo-payment min-size" />
                  </li> -->
                  <li>
                    <img width="150px" height="30px" src="img/payments/transbank.svg" alt="Transbank" class="logo-payment max-size" />
                  </li>
                </ul>
              </div>
            </div>
          </div>
        </div>
      </div>
    </footer>
  </div>
</template>

<script>
export default {
  data: () => ({
    enlace: process.env.VUE_APP_PORTAL_URL
  })
};
</script>

<style scoped>
@media (max-width:500px){
  .pt-copyright{
    font-size: 13px !important;
  }

}
</style>